<template>
  <div>

    <quill-editor
      ref="QuillEditor"
      v-model="content"
      :disabled="readonly"
      :options="editorOption"
      :style="{height:heigh+'px'}"
      @change="onEditorChange($event)" />
    <upload ref="upload"
      :updata="updata"
      @successData="successData" />
    <upload ref="uploadMp3"
      style="display: none;"
      :updata="updataMp3"
      @successData="successDataMp3" />
  </div>
</template>
  
  <script>
import upload from '@/components/upload/upload1'
import { quillEditor, Quill } from 'vue-quill-editor'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { toolbarOptions } from './toolbar'
import { getStore } from '@/util/store'
Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)
const Size = Quill.import('attributors/style/size')
Size.whitelist = ['10px', '14px', '16px', '18px', '20px', '24px']
Quill.register(Size, true)
const Font = Quill.import('formats/font')
Font.whitelist = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif']
Quill.register(Font, true)
export default {
  name: 'Index',
  components: {
    quillEditor,
    upload
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    heigh: {
      type: Number,
      default: 300
    },
    isPushContent: {
      type: Boolean,
      default: false
    },
    quesType:{
      type:Number,
      default:1
    }
  },
  data() {
    const that = this
    return {
      flag: true,
      action: '', // HTTP_URI.UPLOADIMG,
      headers: {
        token: getStore({ name: 'token' })
      },
      content: '',
      editorOption: {
        placeholder: '请输入',
        theme: 'snow',
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: function (value) {
                if (that.readonly) return
                if (value) {
                  // document.querySelector('.el-upload').click()
                  // console.log(this.$refs.upload)
                  that.initUpload()
                  // 触发element upload 单机事件
                } else {
                  this.quill.format('image', false)
                }
              },
              link: function (value) {
                if (that.readonly) return false
                if (value) {
                  that.initUploadMp3()
                } else {
                  this.quill.format('link', false)
                }
              }
            }
          },
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar']
          },
        },
        // keyboard: {
        //   bindings: {
        //     shift_enter: {
        //       key: 13,
        //       shiftKey: true,
        //       handler: (range, ctx) => {
        //         console.log(6666);
        //         this.editor.insertText(range.index, '\n');
        //       }
        //     },
        //   }
        // }
      },
      replyTo: '',
      updata: {
        upLoadType: 5, //样式类型 1按钮  2拖拽 3图标icon 4+图标反显图片  ---------必传参数
        bizModel: 'means', // 模块 ---------必传参数general默认值
        fileList: false, // 反显列表数据
        fileUrl: '', // 反显图片
        fileTypes: ['png', 'jpg'], //限制上传格式,   不传、length为0-----不限制格式
        disabled: false, //禁用上传
        size: 10,
        sizeType: 'MB',
        limit: 20, //上传文件个数
        timeout: 0, //超时毫秒数, 默认为0-不超时 超时错误可以重试上传
        concurrentRequestLimit: 5, //并发数，谷歌最大6
        retryCount: 0, //上传重试, 默认为0
        blockSize: 10, //分片大小 单位M
        flag: true
      },
      updataMp3: {
        upLoadType: 5, // 样式类型 1按钮  2拖拽 3图标icon 4+图标反显图片  ---------必传参数
        bizModel: 'means', // 模块 ---------必传参数general默认值
        fileList: false, // 反显列表数据
        fileUrl: '', // 反显图片
        fileTypes: ['mp3', 'mp4'], // 限制上传格式,   不传、length为0-----不限制格式
        disabled: false, // 禁用上传
        size: 100,
        sizeType: 'MB',
        limit: 20, // 上传文件个数
        timeout: 0, // 超时毫秒数, 默认为0-不超时 超时错误可以重试上传
        concurrentRequestLimit: 5, // 并发数，谷歌最大6
        retryCount: 0, // 上传重试, 默认为0
        blockSize: 10, // 分片大小 单位M
        flag: true
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        this.content = val
      },
      immediate: true
    }
  },
  mounted() {
    // this.$refs.QuillEditor.quill.keyboard.bindings['188'].forEach(item => {
    //   console.log(item);
    //   if (item.collapsed) item.collapsed = false
    //   if (item.empty) item.empty = false
    //   item.handler = () => false
    // })
    if(this.quesType == 1){
      this.$refs.QuillEditor.quill.root.addEventListener('keypress', (e) => {
        console.log(e);
        if (e.key === ',') {
          console.log("输入了逗号");
          e.preventDefault();
        }
      });
    }
  },
  methods: {
    // customKeyHandler(range, context) {
    //   // 自定义按键功能的逻辑
    //   console.log('Custom key pressed!');
    //   // 例如，你可以在这里插入文本或者调用其他方法
    // },
    onSelectd() {},
    getEditorValue() {
      return this.content
    },
    setEditorValue(content = '') {
      this.content = content
    },
    handleAvatarError() {
      this.$message.warning('网络繁忙，请稍后上传')
    },
    beforeUpload(file) {
      if (file.type == 'image/jpeg' || file.type == 'image/png') {
        const isLt10M = file.size / 1024 / 1024 < 10
        if (!isLt10M) this.$message.error('图片大小不能超过10M')
        return isLt10M
      } else {
        this.$message.error('图片格式错误')
        return false
      }
    },
    onEditorChange({ quill, html, text }) {
      if (text.length < 99999) {
        if(this.quesType == 1){
          this.content = html.replaceAll(/,/g, '').replaceAll(/，/g, '')
        }else{
          this.content = html
        }
        this.$emit('input', this.content)
        this.$emit('change', this.content)
      } else {
        this.$message.error('超出最大输入限制!')
      }
    },
    // 文件上传成功回调
    successData(file) {
      console.log(file)
      const quill = this.$refs.QuillEditor.quill
      const length = quill.selection.savedRange.index
      // 向富文本内容中插入图片
      quill.insertEmbed(length, 'image', 'https://video.bfjyjt.cn' + file.fileUrl)
      // 移动焦点光标位置
      quill.setSelection(length + 1)
    },

    // 文件上传成功回调
    successDataMp3(file) {
      const quill = this.$refs.QuillEditor.quill
      const length = quill.selection.savedRange.index
      // 插入图片  res.info为服务器返回的图片地址
      const BlockEmbed = Quill.import('blots/block/embed')
      class AudioBlot extends BlockEmbed {
        static create(value) {
          console.log(value)
          const node = super.create()
          node.setAttribute('src', 'https://video.bfjyjt.cn' + file.fileUrl) // 设置audio的src属性
          node.setAttribute('controls', true) // 设置audio的controls，否则他将不会显示
          node.setAttribute('controlsList', 'nodownload') // 设置audio的下载功能为不能下载
          node.setAttribute('id', 'voice') // 设置一个id
          return node
        }
      }
      AudioBlot.blotName = 'audio'
      AudioBlot.tagName = 'audio' // 自定义的标签为audio
      Quill.register(AudioBlot)
      // insertEmbed(index: Number(插入的位置), type: String(标签类型), value: any(参数，将传入到create的方法中去), source: String = 'api')
      quill.insertEmbed(length, 'audio', { url: 'https://video.bfjyjt.cn' + file.fileUrl }, 'api')
      quill.setSelection(length + 1)
    },

    insertHtml(content) {
      // 设置编辑器获取焦点
      this.editor.events.focus()
      // 获取选定对象
      const selection = getSelection()
      // 判断是否有最后光标对象存在
      if (this.lastEditRange) {
        // 存在最后光标对象，选定对象清除所有光标并添加最后光标还原之前的状态
        selection.removeAllRanges()
        selection.addRange(this.lastEditRange)
      }
      // 插入内容
      this.editor.html.insert(content)
      // 记录最后光标停留位置
      this.lastEditRange = selection.getRangeAt(0)
    },
    // 初始化上传问题
    initUpload() {
      console.log(this.$refs.upload)
      this.$refs.upload.getUploadDom()
    },
    initUploadMp3() {
      this.$refs.uploadMp3.getUploadDom()
    }
  }
}
</script>
  
  <style>
@import url('./css/index.css');
.icon {
  font-size: 16px;
  color: #444;
  margin-right: 10px;
  position: absolute;
  top: 3px;
  background-color: #fefefe;
  left: 20px;
}
.icon:hover i {
  color: #1976d2;
}

.ql-container {
  height: 100% !important;
}
.quill-editor {
  line-height: normal !important;
}
</style>
  